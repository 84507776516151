import React from "react"
import AccreditationSlider from "../../sliders/AccreditationSlider"
import img1 from '../../../../images/quality_kan.jpg';
import img2 from '../../../../images/quality_npra.jpg';
import img3 from '../../../../images/quality_ujiklinik.jpg';

const Accreditations = () => {
  // const accreditations = [
  //   {
  //     id: 1,
  //     // name: "John Smith",
  //     testimony: "Pedoman Cara Uji Klinik yang Baik",
  //     image: img1,
  //   //   company:
  //   //     "The National Agency of Drug and Food Control Jakarta: BPOM RI. 2016.",
  //   },
  //   {
  //     id: 2,
  //     // name: "John Smith",
  //     testimony: "Guideline for Good Clinical Practice E6 (R2)",
  //     image: img2,
  //   //   company:
  //   //     "ICH Expert Working Group. ICH Harmonised Tripartite Guideline, 2016.",
  //   },
  //   {
  //     id: 3,
  //     // name: "John Smith",
  //     testimony: "Good Laboratory Practice Principles",
  //     image: img3,
  //   //   company:
  //   //     "(ISO/IEC 17025)/GLP General requirements for the competence of testing and calibration laboratories, 2017.",
  //   },
  // ]
  return (
    <section className="slider" >
      <div className="container">
        <h2 className="title-heading">Accreditation</h2>
        <h5 className="">Our company is reviewed and validated by high standing institutions, ensuring the best result in every outcome we provide.</h5>
        <AccreditationSlider/>
      </div>
    </section>
  )
}

export default Accreditations

{
  /* <ContentSlider
        contents={testimonies}
        blogPostCls="post-style-1"
        imgEffect="rotate"
      /> */
}
